// utils/faqSchema.ts
import type {
  FaqSchema,
  FaqItem,
} from "../../storyblok/types/blocks/AccordionBlock";

export const stripMarkdown = (text: string): string => {
  return text.replace(/[#*_]/g, "").trim();
};

export const convertMarkdownToFaqHtml = (text: string): string => {
  const htmlTransformations = [
    // Links to text only
    { pattern: /\[([^\]]+)\]\([^)]+\)/g, replacement: "$1" },
    // Headers to paragraphs
    { pattern: /#{1,6}\s+(.*?)(?:\n|$)/g, replacement: "<p>$1</p>" },
    // Bold text to <b> tags
    { pattern: /\*\*(.*?)\*\*/g, replacement: "<b>$1</b>" },
    { pattern: /__(.*?)__/g, replacement: "<b>$1</b>" },
    // Bullet points to paragraphs
    { pattern: /^\s*[-*+]\s+(.*?)(?:\n|$)/gm, replacement: "<p>$1</p>" },
    // Preserve line breaks
    { pattern: /([^>\n])\n(?!<)(?!\n)/g, replacement: "$1<br/>" },
  ];

  let html = text;
  htmlTransformations.forEach(({ pattern, replacement }) => {
    html = html.replace(pattern, replacement);
  });

  const paragraphs = html.split(/\n\s*\n/);
  html = paragraphs
    .map((p) => {
      const trimmed = p.trim();
      if (!trimmed) return "";
      if (trimmed.startsWith("<p>") && trimmed.endsWith("</p>")) return trimmed;
      return `<p>${trimmed}</p>`;
    })
    .filter(Boolean)
    .join("");

  return html
    .replace(/<p><p>/g, "<p>")
    .replace(/<\/p><\/p>/g, "</p>")
    .replace(/\s+/g, " ")
    .trim();
};

export const createFaqSchema = (items: FaqItem[]): FaqSchema => {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: items.map((item) => ({
      "@type": "Question",
      name: stripMarkdown(item.title),
      acceptedAnswer: {
        "@type": "Answer",
        text: convertMarkdownToFaqHtml(item.description),
      },
    })),
  };
};
