import type { FaqItem } from "../../storyblok/types/blocks/AccordionBlock";

class FaqManagerService {
  private items = new Set<FaqItem>();
  private listeners = new Set<() => void>();

  public addItems(newItems: FaqItem[]): void {
    newItems.forEach((item) => this.items.add(item));
    this.notifyListeners();
  }

  public removeItems(itemsToRemove: FaqItem[]): void {
    itemsToRemove.forEach((itemToRemove) => {
      this.items.forEach((item) => {
        if (item._uid === itemToRemove._uid) {
          this.items.delete(item);
        }
      });
    });
    this.notifyListeners();
  }

  public subscribe(listener: () => void): () => void {
    this.listeners.add(listener);
    return () => {
      this.listeners.delete(listener);
    };
  }

  public getAllItems(): FaqItem[] {
    return Array.from(this.items);
  }

  private notifyListeners(): void {
    this.listeners.forEach((listener) => listener());
  }
}

export const FaqManager = new FaqManagerService();
