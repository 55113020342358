import React, { useState, useEffect } from "react";
import Head from "next/head";
import { FaqManager } from "../../../lib/utils/schema/FaqManager";
import { createFaqSchema } from "../../../lib/utils/schema/FaqItem";
import type { FaqItem } from "../../../lib/storyblok/types/blocks/AccordionBlock";

export const GlobalFaqSchema: React.FC = () => {
  const [items, setItems] = useState<FaqItem[]>(FaqManager.getAllItems());

  useEffect(() => {
    return FaqManager.subscribe(() => {
      setItems(FaqManager.getAllItems());
    });
  }, []);

  if (items.length === 0) return null;

  const faqSchema = createFaqSchema(items);

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(faqSchema) }}
      />
    </Head>
  );
};
