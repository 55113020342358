import { register } from "swiper/element/bundle";
import type { AppProps } from "next/app";
import { GlobalFaqSchema } from "../ui/Common/FaqSchema/FaqSchema";
import "../styles/global.scss";
import { appWithTranslation } from "next-i18next";

register();

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <GlobalFaqSchema />
      <Component {...pageProps} />
    </>
  );
}
export default appWithTranslation(MyApp);
